import React from "react";
import { Link } from "gatsby";
import { IntlContextConsumer } from "gatsby-plugin-intl";

import "./index.scss";

const PficStatements = ({ data = [] }) => {
  return (
    <IntlContextConsumer>
      {({ language: currentLocale }) => (
        <div className="py-8 lg:py-16">
          <div className="global-x-spacing px-0 lg:px-16 max-w-78 mx-auto">
            <div className="flex border-b border-secondary pb-4 mb-4 lg:mb-8">
              <h4 className="text-secondary uppercase mr-8">
                {currentLocale === "fr" ? "INVESTISSEURS AMÉRICAINS" : "US INVESTORS"}
              </h4>
            </div>
            <div className="md:max-w-185 items-list-wrapper">
              <ul className="mb-8 lg:mb-4">
                {data.map(({ link, title }) => (
                  <li className="bg-grow-blue rounded-lg py-4 px-4 hover:py-8 flex mb-2 items-start lg:items-center">
                    <span className="hidden md:block font-xs tracking-wider w-1/3 md:1/4 lg:w-1/6 pr-4">
                      {currentLocale === "fr" ? "VOIR" : "VIEW"}
                    </span>
                    <span className="lg:items-center flex-1 flex flex-col lg:flex-row lg:pl-8 truncate">
                      <span className="flex-1 flex items-center lg:order-2 lg:pl-16">
                        <Link
                          to={link}
                          target="_blank"
                          className="text-primary underline uppercase font-xs tracking-wider truncate"
                        >
                          {title}
                        </Link>
                      </span>
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      )}
    </IntlContextConsumer>
  );
};

export default PficStatements;
